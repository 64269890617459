import {SET_USER} from "@/store/mutations";

export default {
    extract(res, status = [200]) {
        return new Promise((resolve, reject) => {
            if (status.includes(res.status)) {
                res.json()
                    .then(data => resolve(data))
                    .catch(reject);
            } else {
                res.json()
                    .then(data => reject(data))
                    .catch(() => reject({status: res.status, message: ""}));
            }
        });
    },
    checkStatus(res, status = []) {
        return new Promise((resolve, reject) => {
            if (status.includes(res.status)) {
                resolve(res);
            } else {
                reject(res);
            }
        });
    },
    handleError(_this, err) {
        if (err.status === 401) {
            _this.$store.commit(SET_USER, null);
            setTimeout(() => {
                window.location.href = `/frontdesk/login?redirect=${window.location.href}`;
            }, 3000);
        } else if (err.status === 403) {
            _this.$router.replace({name: "Unauthorized"});
            this.redirectToHome();
        } else if (err.status === 404) {
            _this.$router.replace({name: "404 Not found"});
            this.redirectToHome();
        } else {
            _this.$router.push({name: "Server down"});
            this.redirectToHome();
        }
    },
    redirectToHome() {
        setTimeout(() => {
            this.$router.push({name: 'Home'});
        }, 5000);
    }
}
import Videos from "./videos.services"
import Playlists from "./playlists.services"
import Pipelines from "./pipelines.services"
import Imdb from "./imdb.service"

export default {
    Videos,
    Playlists,
    Pipelines,
    Imdb
}
import Vue from "vue";
import Utils from "@/services/api/utils";

const FRONTDESK_URL = 'frontdesk-api';

export default {
    /**
     * Get the list of videos
     * @returns {Promise<Response>}
     */
    async getAllVideos() {
        return await fetch(`/${FRONTDESK_URL}/videos`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Get the data of a video
     * @returns {Promise<Response>}
     */
    async getVideo(id) {
        return await fetch(`/${FRONTDESK_URL}/videos/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Add a new video
     * @param video
     * @returns {Promise<Response>}
     */
    async addVideo(video) {
        return fetch(`/${FRONTDESK_URL}/videos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(video)
        }).then(res => Utils.extract(res, [200]));
    },

    /**
     * Update a particular video
     * @param video
     * @returns {Promise<Response>}
     */
    async updateVideo(video) {
        return await fetch(`/${FRONTDESK_URL}/videos`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(video)
        }).then(Utils.extract);
    },

    /**
     * Delete a video
     * @param id
     * @returns {Promise<Response>}
     */
    async deleteVideo(id) {
        return await fetch(`/${FRONTDESK_URL}/videos/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Retrieve imdb videos
     * @param title
     * @returns {Promise<T>}
     */
    async getImdbVideos(title) {
        return await fetch(`/${FRONTDESK_URL}/videos/imdb/${title}`, {
            method: 'GET',
        }).then(Utils.extract);
    },
}
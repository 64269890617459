import Vue from "vue";
import Utils from "@/services/api/utils";

const FRONTDESK_URL = 'frontdesk-api/imdb';

export default {
    /**
     * Get the list of elements for title
     * @param title
     * @returns {Promise<T>}
     */
    async listForTitle(title) {
        return await fetch(`/${FRONTDESK_URL}/find?q=${encodeURI(title)}`, {
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        })
            .then(Utils.extract)
            .then(list => {
                console.log("list :", list);
                return list.filter(e => e.title && e.year)
            });
    },

    /**
     * Get imdb details for an element
     * @param imdbId
     * @returns {Promise<T>}
     */
    async getDetails(imdbId) {
        return await fetch(`/${FRONTDESK_URL}/details?tconst=${encodeURI(imdbId)}`, {
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },
}

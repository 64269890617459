import Vue from "vue";
import Utils from "@/services/api/utils";

const FRONTDESK_BASE_URL = 'frontdesk-api';
const PIPELINES_URL = `${FRONTDESK_BASE_URL}/pipelines`;
const RUNNERS_URL = `${FRONTDESK_BASE_URL}/workers`;
const QUEUE_URL = `${FRONTDESK_BASE_URL}/queue`;

export default {
    /**
     * Get the list of pipelines
     * @returns {Promise<Response>}
     */
    async getAllPipelines() {
        return await fetch(`/${PIPELINES_URL}/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Get on pipeline
     * @returns {Promise<Response>}
     */
    async getOnePipeline(id) {
        return await fetch(`/${PIPELINES_URL}/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Get the list of pipelines
     * @returns {Promise<Response>}
     */
    async getAllRunners() {
        return await fetch(`/${RUNNERS_URL}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Get on pipeline
     * @returns {Promise<Response>}
     */
    async updateRunner(runner) {
        return await fetch(`/${RUNNERS_URL}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(runner)
        }).then(Utils.extract);
    },

    /**
     * Get runner admin details
     * @returns {Promise<Response>}
     */
    async getRunnersAdminDetails() {
        return await fetch(`/${RUNNERS_URL}/admin`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Update runner admin details
     * @returns {Promise<Response>}
     */
    async updateRunnersAdminDetails(runner) {
        return await fetch(`/${RUNNERS_URL}/admin`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(runner)
        }).then(Utils.extract);
    },

    /**
     * Get queued jobs
     * @returns {Promise<Response>}
     */
    async getQueuedJobs() {
        return await fetch(`/${QUEUE_URL}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Get logs
     * @returns {Promise<Response>}
     */
    async getJobsLogs(request) {
        return await fetch(`/${QUEUE_URL}/logs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(request)
        }).then(Utils.extract);
    }
}
import Vue from "vue";
import Utils from "@/services/api/utils";

const FRONTDESK_URL = 'frontdesk-api';

export default {
    /**
     * Get the list of playlists
     * @returns {Promise<Response>}
     */
    async getAllPlaylists() {
        return await fetch(`/${FRONTDESK_URL}/playlists`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Get the data of a playlist
     * @returns {Promise<Response>}
     */
    async getPlaylist(id) {
        return await fetch(`/${FRONTDESK_URL}/playlists/find/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    },

    /**
     * Add a new playlist
     * @param playlist
     * @returns {Promise<Response>}
     */
    async addPlaylist(playlist) {
        return fetch(`/${FRONTDESK_URL}/playlists`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(playlist)
        }).then(res => Utils.extract(res, [200]));
    },

    /**
     * Update a particular playlist
     * @param playlist
     * @returns {Promise<Response>}
     */
    async updatePlaylist(playlist) {
        return await fetch(`/${FRONTDESK_URL}/playlists`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
            body: JSON.stringify(playlist)
        }).then(Utils.extract);
    },

    /**
     * Delete a playlist
     * @param id
     * @returns {Promise<Response>}
     */
    async deletePlaylist(id) {
        return await fetch(`/${FRONTDESK_URL}/playlists/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${Vue.prototype.$keycloak.token}`
            },
        }).then(Utils.extract);
    }
}
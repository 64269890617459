<template>
    <div>
        <v-alert v-model="success" type="success" dismissible>
            {{ message }}
        </v-alert>
        <v-alert v-model="error" type="error" dismissible>
            {{ message }}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "Alert",
    data: () => ({
        success: false,
        error: false,
        message: ""
    }),
    methods: {
        /**
         * show a success message
         * @param msg
         */
        showSuccessMessage(msg) {
            this.error = false;
            this.message = msg;
            this.success = true;
        },
        /**
         * show an error message
         * @param msg
         */
        showErrorMessage(msg) {
            this.success = false;
            this.message = msg;
            this.error = true;
        }
    }
}
</script>
